<template>
  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <router-link :to="'/'" class="brand-link">
      <img src="../../assets/img/logo.png" alt="Logo" class="brand-image img-circle elevation-3"
        style="background: white;" />
      <span class="brand-text font-weight-light">{{ appName }}</span>
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar pt-2 pb-2">

      <!-- Sidebar Menu -->
      <nav>
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
          <!-- <li class="nav-item">
            <router-link :to="'/'" class="nav-link">
              <i class="nav-icon fas fa-tachometer-alt"></i>
              <p>
                Dashboard
                <i class="right fas fa-angle-left"></i>
              </p>
            </router-link>
            <ul class="nav nav-treeview">
              <li class="nav-item">
                <router-link :to="'/'" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Dashboard v1</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="'/'" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Dashboard v2</p>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link :to="'/'" class="nav-link">
                  <i class="far fa-circle nav-icon"></i>
                  <p>Dashboard v3</p>
                </router-link>
              </li>
            </ul>
          </li> -->
          <li class="nav-item">
            <router-link :to="'/'" :class="'nav-link' + (checkNavActive('/') ? ' active' : '')">
              <i class="nav-icon fas fa-home"></i>
              <p>Trang chủ</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="'/student'" :class="'nav-link' + (checkNavActive('/student') ? ' active' : '')">
              <i class="nav-icon fas fa-user-graduate"></i>
              <p>Học viên</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="'/teacher'" :class="'nav-link' + (checkNavActive('/teacher') ? ' active' : '')">
              <i class="nav-icon fas fa-user-tie"></i>
              <p>Giáo viên</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAdmin()">
            <router-link :to="'/user'" :class="'nav-link' + (checkNavActive('/user') ? ' active' : '')">
              <i class="nav-icon fas fa-user-cog"></i>
              <p>Quản trị viên</p>
            </router-link>
          </li>
          <li class="nav-item" v-if="isAdmin()">
            <router-link :to="'/stadium'" :class="'nav-link' + (checkNavActive('/stadium') ? ' active' : '')">
              <i class="nav-icon fas fa-futbol"></i>
              <p>Sân vận động</p>
            </router-link>
          </li>
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->

    <!-- <div class="sidebar-custom">
      <button class="btn click text-white pos-right mr-1" v-on:click="showMenu = !showMenu" data-widget="pushmenu">
        <i v-if="!showMenu" class="fas fa-chevron-right" data-widget="pushmenu"></i>
        <i v-if="showMenu" class="fas fa-chevron-left" data-widget="pushmenu"></i>
      </button>
    </div> -->
  </aside>
</template>

<script>
export default {
  data: function () {
    return {
      showMenu: false
    };
  },
  methods: {
    checkNavActive(url) {
      const pathName = location.pathname;
      if (url == "/") return pathName == url;
      return pathName.indexOf(url) >= 0;
    }
  },
  watch: {
    $route() {
      this.$forceUpdate();
    }
  }
};
</script>